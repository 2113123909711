import * as React from 'react';
import { Search, Dropdown, Form, Input } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import './index.css';
// @ts-ignore
import arrow from '../../images/white-right-arrow.svg';

interface ExploreJobsSectionProps {
	data: any;
}
const getJobData = (data: any) => {
	let _tempJobs = [];
	let allJobs = data?.allSitePage?.edges;
	for (let job in allJobs) {
		if (allJobs[job]?.node?.pageContext) {
			if (allJobs[job]?.node?.pageContext?.title) {
				_tempJobs.push(allJobs[job]?.node?.pageContext);
			}
		}
	}
	return _tempJobs;
};
const ExploreJobsSection: React.FC<ExploreJobsSectionProps> = (
	props: ExploreJobsSectionProps
) => {
	const [jobs, setJobs] = useState(getJobData(props.data));
	const [categories, setCategories]: any = useState([]);
	const [jobTypes, setJobTypes]: any = useState([]);
	const [selectedCategory, setSelectedCategory] = useState();
	const [selectedJobType, setSelectedJobType] = useState();

	useEffect(() => {
		let tempCategories: object[] = [];
		let tempJobTypes: object[] = [];
		for (const job in jobs) {
			if (jobs.hasOwnProperty(job)) {
				if (
					!tempCategories.some(
						(e: any) => e.key === jobs[job].category
					)
				) {
					const _category = jobs[job].category;
					tempCategories.push({
						key: _category,
						text: _category,
						value: _category
					});
				}
				if (
					!tempJobTypes.some((e: any) => e.key === jobs[job].fullTime)
				) {
					const _jobType = jobs[job].fullTime;
					tempJobTypes.push({
						key: _jobType,
						text: _jobType,
						value: _jobType
					});
				}
			}
		}
		setCategories(tempCategories);
		setJobTypes(tempJobTypes);
	}, [jobs]);

	const [searchTerm, setSearchTerm] = useState('');

	const handleTermChange = (e: any) => {
		setSearchTerm(e.target.value);
	};
	const onCategoryDropdownChangeHandler = (e: any, res: any) => {
		setSelectedCategory(res.value);
	};
	const onJobTypeDropdownChangeHandler = (e: any, res: any) => {
		setSelectedJobType(res.value);
	};

	const onSearchHandler = () => {};
	return (
		<section
			className={
				'explore-jobs-section z-50 px-[20px] pt-[40px] pb-[12px]'
			}
		>
			<div className={'container mx-auto'}>
				<h2
					className={
						'font-dinblack text-[48px] font-bold text-otr-blue'
					}
				>
					EXPLORE OUR JOBS
				</h2>

				<Form
					className={
						'search-fields flex flex-row flex-wrap gap-[16px] pb-[16px]'
					}
					action={'/search/'}
				>
					<Search
						showNoResults={true}
						noResultsMessage={''}
						name="term"
						onSearchChange={handleTermChange}
						// icon={searchTermIcon}
						minCharacters={32143124123412}
						label=""
						className={'max-lg:w-full'}
						value={searchTerm}
						placeholder={
							'Search: Zip Code, Job Title, Location, etc...'
						}
					/>
					{/*<Dropdown*/}
					{/*	placeholder="Job Type"*/}
					{/*	className={'max-lg:w-full'}*/}
					{/*	onChange={onJobTypeDropdownChangeHandler}*/}
					{/*	value={selectedJobType}*/}
					{/*	name={'type'}*/}
					{/*	selection*/}
					{/*	clearable*/}
					{/*	search*/}
					{/*	options={jobTypes}*/}
					{/*/>*/}
					{/*<Dropdown*/}
					{/*	placeholder="Category"*/}
					{/*	className={'max-lg:w-full'}*/}
					{/*	onChange={onCategoryDropdownChangeHandler}*/}
					{/*	value={selectedCategory}*/}
					{/*	name={'category'}*/}
					{/*	selection*/}
					{/*	search*/}
					{/*	clearable*/}
					{/*	options={categories}*/}
					{/*/>*/}
					{/*<input*/}
					{/*	name={'category'}*/}
					{/*	id="category"*/}
					{/*	hidden*/}
					{/*	value={selectedCategory}*/}
					{/*/>*/}
					{/*<input*/}
					{/*	name={'type'}*/}
					{/*	id="type"*/}
					{/*	hidden*/}
					{/*	value={selectedJobType}*/}
					{/*/>*/}
					<button
						type={'submit'}
						onClick={onSearchHandler}
						className="flex h-[55px] w-full w-[262px] flex-row items-center justify-end bg-[#0091FE] font-open-sans text-[22px] font-bold uppercase leading-[28px] text-white"
					>
						<span className="pr-[40px]">SEARCH JOBS</span>{' '}
						<div className="m-0 flex h-[55px] w-[55px] flex-col items-center justify-center bg-[#037EDC] p-0">
							<img src={arrow} alt="" width={24.89} height={21} />
						</div>
					</button>
				</Form>
			</div>
		</section>
	);
};

export default ExploreJobsSection;
